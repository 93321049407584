<template>
  <div class="detail-view p-4">
    <loading-screen v-if="loading" />
    <div v-if="!loading" class="container">
      <div class="content flex">
        <div class="w-full md:w-1/2 xl:w-1/4 m-auto">
          <router-link to="/">
            <b-button variant="secondary" class="mb-4">
              <b-icon name="arrow-left" class="mr-2" />
              {{ $t("return") }}
            </b-button>
          </router-link>
          <b-content-card :item="item" :config="config" type="detail" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BIcon from "@/components/BIcon";
import BContentCard from "@/components/BContentCard";
import LoadingScreen from "@/components/LoadingScreen";
import FieldTypes from "@/constants/field-types";

export default {
  name: "DetailView",

  metaInfo() {
    const metaDescription = this.metaDescription;
    const metaTitle = this.metaTitle;
    let proMetaInfo = {};

    if (this.isPro) {
      proMetaInfo = {
        title: metaTitle,
        meta: [
          { name: "description", content: metaDescription },
          { property: "og:title", content: metaTitle },
          { property: "og:type", content: "website" },
          { property: "og:description", content: metaDescription }
        ]
      };
    }

    return {
      ...proMetaInfo
    };
  },

  components: {
    BContentCard,
    BIcon,
    LoadingScreen
  },

  data() {
    return {
      loading: true,
      fieldTypes: FieldTypes,
      itemIndex: -1
    };
  },

  computed: {
    isPro() {
      return this.$store.state.config.isPro;
    },
    metaDescription() {
      return (
        this.item[this.$store.state.config?.metaFields?.itemDescription] ||
        this.description.value ||
        ""
      );
    },
    metaTitle() {
      return (
        this.item[this.$store.state.config?.metaFields?.itemTitle] ||
        this.title.value ||
        ""
      );
    },
    config() {
      return this.$store.state.config.detailView;
    },
    description() {
      return {
        type: this.config?.description?.type,
        value: this.item[this.config?.description?.name]
      };
    },
    title() {
      return {
        type: this.config?.title?.type,
        value: this.item[this.config?.title?.name]
      };
    },
    items() {
      return this.$store.state.items;
    },
    item() {
      if (this.itemIndex >= 0) {
        return this.items[this.itemIndex];
      }
      return {};
    }
  },

  watch: {
    config(newVal) {
      if (newVal) {
        this.getItems();
      }
    }
  },

  beforeMount() {
    if (!this.items.length && this.config) {
      this.getItems();
    } else if (!this.config) {
      this.loading = true;
    } else {
      this.loading = false;
      this.itemIndex = this.items.findIndex(
        item => item.slug == this.$route.params.slug
      );
    }
  },

  methods: {
    async getItems() {
      this.loading = true;
      await this.$store.dispatch("getItems");

      this.itemIndex = this.items.findIndex(
        item => item.slug == this.$route.params.slug
      );

      if (this.itemIndex === -1) {
        this.$router.push({ name: "404" });
      }

      this.loading = false;
    },

    visitLink(link) {
      window.open(link);
    }
  }
};
</script>
