<template>
  <div class="not-found">
    <h1 class="is-size-1"><b>404</b> Page Not Found</h1>
    <div>
      <router-link :to="{ name: 'home' }">
        {{ $t("return_home") }}
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "NotFound"
};
</script>

<style lang="scss" scoped>
.not-found {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  width: 100%;
}
</style>
