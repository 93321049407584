import Vue from "vue";
import VueRouter from "vue-router";
import MainLayout from "../layouts/Main.vue";

const DetailView = () =>
  import(/* webpackChunkName: "detail" */ "../views/DetailView.vue");
const ListView = () =>
  import(/* webpackChunkName: "list" */ "../views/ListView.vue");
const MarkdownContent = () =>
  import(
    /* webpackChunkName: "markdown-content" */ "../views/MarkdownContent.vue"
  );
const NotFound = () =>
  import(/* webpackChunkName: "not-found" */ "../views/NotFound.vue");

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "main",
    redirect: { name: "home" },
    component: MainLayout,
    children: [
      {
        path: "/",
        name: "home",
        component: ListView
      },
      {
        path: "/privacy-policy",
        name: "privacy-policy",
        component: MarkdownContent
      },
      {
        path: "/:slug",
        name: "detail-view",
        component: DetailView
      }
    ]
  },
  {
    path: "/*",
    name: "404",
    component: NotFound
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
