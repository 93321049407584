import * as mutation from "./mutation-types";
import { hasSameVariants } from "./helpers";
import CheckoutFormFields from "@/constants/checkout/form-fields";
import i18n from "../../../i18n";
import PaymentMethods from "@/constants/checkout/payment-method";
import ReceiveModes from "@/constants/checkout/receive-mode";
import VariantTypes from "@/constants/checkout/variant-type";
import money from "@/utils/money";
import StringUtils from "@/utils/string";
import currency from "currency.js";
import _cloneDeep from "lodash-es/cloneDeep";
import _snakeCase from "lodash-es/snakeCase";

const configModule = "config";
const titleField = "title";
const listConfig = "listView";
const imageField = "image";

export default {
  selectItem({ commit, dispatch, rootState, state }, item) {
    const productOptions = rootState[configModule].productOptions;
    let itemHasOptions = false;

    for (let i = 0; i < productOptions.length; i++) {
      if (itemHasOptions) break;

      for (let j = 0; j < productOptions[i].options.length; j++) {
        if (item[productOptions[i].options[j].name]) {
          itemHasOptions = true;
          break;
        }
      }
    }

    if (productOptions && !state.showProductOptionsModal && itemHasOptions) {
      commit(mutation.SHOW_PRODUCT_OPTIONS_MODAL, true);
      commit(mutation.SET_SELECTED, item);
    } else {
      dispatch("addToCart", item);
      commit(mutation.SHOW_PRODUCT_OPTIONS_MODAL, false);
    }
  },

  addToCart({ commit, state, rootState }, item) {
    const shoppingCartItem = state.items.find(el => {
      if (el.id === item.id && hasSameVariants(el?.variants, item?.variants)) {
        return true;
      }
      return false;
    });

    if (shoppingCartItem) {
      commit(mutation.INCREMENT_ITEM, _cloneDeep(shoppingCartItem));
    } else {
      const cardConfig = rootState[configModule][listConfig];
      const checkoutConfig = rootState[configModule].checkout;
      const imageFields = cardConfig?.[imageField]?.name;
      const cardImage = {
        value: Array.isArray(imageFields) ? item[imageFields[0]] || "" : "",
        type: cardConfig?.image?.type || ""
      };
      const imageValue =
        StringUtils.directDownloadLink(cardImage.value) || cardImage.value;
      const itemPrice = item[checkoutConfig.priceField];
      const currencyCode = itemPrice.replace(/[0-9.,]/g, "");
      const itemName = item[cardConfig?.[titleField]?.name];
      let itemTotal = currency(itemPrice);

      // Add cost of variants
      if (item.variants) {
        item.variants.forEach(variant => {
          if (
            variant.type === VariantTypes.NUM_SINGLE_CHOICE &&
            variant.selected
          ) {
            itemTotal = itemTotal.add(currency(variant.selected.value).value);
          } else if (
            variant.type === VariantTypes.NUM_MULTIPLE_CHOICE &&
            variant.selected.length
          ) {
            variant.selected.forEach(multChoice => {
              itemTotal = itemTotal.add(currency(multChoice.value).value);
            });
          }
        });
      }

      const parsedItem = {
        id: item.id,
        index: state.items.length,
        image: imageValue,
        name: itemName,
        price: itemTotal.value,
        amount: 1,
        variants: item.variants || []
      };
      commit(mutation.ADD_ITEM, _cloneDeep(parsedItem));

      if (currencyCode) {
        const symbolIndex = itemPrice.indexOf(currencyCode);
        const startsWithCode = symbolIndex === 0;
        const spaceIndex = itemPrice.indexOf(" ");
        const hasMoneySpace = spaceIndex > -1;

        let pattern = "";
        if (startsWithCode) {
          pattern = hasMoneySpace ? "! #" : "!#";
        } else {
          pattern = hasMoneySpace ? "# !" : "#!";
        }
        commit(mutation.SET_CURRENCY, { symbol: currencyCode, pattern });
      }
    }

    commit(mutation.SHOW_SHOPPING_CART, true);
  },

  removeFromCart({ commit }, item) {
    commit(mutation.REMOVE_ITEM, item);
  },

  changeItemAmount({ commit }, { item, amountChange }) {
    if (amountChange < 0) {
      if (item.amount === 1) {
        commit(mutation.REMOVE_ITEM, item);
      } else {
        commit(mutation.SUBSTRACT_ITEM, item);
      }
    } else {
      commit(mutation.INCREMENT_ITEM, item);
    }
  },

  sendOrder({ commit, getters, state, rootState }, contactDetails) {
    let textMessage = rootState[configModule].checkout.helloMessage;
    const name = contactDetails[CheckoutFormFields.NAME];
    const receiveMode = contactDetails[CheckoutFormFields.RECEIVE_MODE];
    let receiveModeName = "";
    const address = contactDetails[CheckoutFormFields.ADDRESS];
    const paymentMethod = contactDetails[CheckoutFormFields.PAYMENT_METHOD];
    const deliveryZone = contactDetails.deliveryZone || null;

    if (receiveMode) {
      receiveModeName =
        receiveMode === ReceiveModes.NUM_PICKUP
          ? i18n.t("pick_up_at_store")
          : i18n.t("deliver");
    }

    if (name) {
      textMessage += `\n${i18n.t("my_name_is")} *${name}*`;

      if (receiveMode === ReceiveModes.NUM_DELIVERY && address) {
        textMessage += `, ${i18n.t("please_deliver_my_order_to", {
          deliver: `*${i18n.t("deliver")}*`
        })} *${address}*`;

        if (deliveryZone?.name) {
          textMessage += ` ${i18n.t("in_the_zone_of", {
            zone: `*${deliveryZone.name}*`
          })}`;
        }
      } else if (receiveMode === ReceiveModes.NUM_PICKUP) {
        textMessage += `, ${i18n.t("i_will_pick_up_my_at_store", {
          pick_up: `*${i18n.t("pick_up_at_store")}*`
        })}`;
      }

      if (paymentMethod === PaymentMethods.NUM_CREDIT_CARD) {
        textMessage += ` ${i18n.t("and")} ${i18n.t("i_will_pay_with", {
          payment_method: `*${i18n.t("credit_card")}*`
        })}`;
      } else if (paymentMethod === PaymentMethods.NUM_CASH) {
        textMessage += ` ${i18n.t("and")} ${i18n.t("i_will_pay_with", {
          payment_method: `*${i18n.t("cash")}*`
        })}`;
      } else if (paymentMethod.length) {
        // Custom payment methods
        textMessage += ` ${i18n.t("and")} ${i18n.t("i_will_pay_with", {
          payment_method: `*${paymentMethod}*`
        })}`;
      }

      textMessage += ".";
    }

    const subtotal = getters.getTotal;
    let total = subtotal;

    // Order detail
    textMessage += `\n ---`;
    state.items.forEach(item => {
      const itemTotal = money.format(
        currency(item.amount).multiply(item.price)
      );
      textMessage += `\n∙ *${item.amount}* x *${item.name}*: ${itemTotal}`;

      // Add selected variants for item
      if (item.variants.length) {
        item.variants.forEach(variant => {
          if (
            variant.type === VariantTypes.NUM_SINGLE_CHOICE &&
            variant.selected
          ) {
            textMessage += `\n  *${variant.name}*`;
            textMessage += `\n   - ${variant.selected.name}`;
          } else if (
            variant.type === VariantTypes.NUM_MULTIPLE_CHOICE &&
            variant.selected.length
          ) {
            textMessage += `\n  *${variant.name}*`;
            variant.selected.forEach(multChoice => {
              textMessage += `\n  - ${multChoice.name}`;
            });
          }
        });
      }
    });

    // Total
    textMessage += "\n---";
    const deliveryCost =
      deliveryZone && deliveryZone.cost
        ? currency(deliveryZone.cost || 0)
        : currency(rootState[configModule].checkout.form.delivery.cost || 0);
    if (receiveMode === ReceiveModes.NUM_DELIVERY && deliveryCost.value) {
      total = currency(total).add(deliveryCost.value);
      textMessage += `\n*Subtotal:* ${money.format(subtotal)}`;
      textMessage += `\n*${i18n.t("delivery_cost")}:* ${money.format(
        deliveryCost.value
      )}`;
    }
    textMessage += `\n*Total:* ${money.format(total)}`;

    // Receive mode
    if (receiveMode) {
      textMessage += `\n---\n*${i18n.t("receive_mode")}:* ${receiveModeName}`;

      if (receiveMode === ReceiveModes.NUM_DELIVERY && !address) {
        textMessage += `\n${i18n.t("address_not_specified")}`;
      } else if (address) {
        textMessage += `\n*${i18n.t("address")}:* ${address}`;
      }

      if (deliveryZone?.name) {
        textMessage += `\n*${i18n.t("delivery_zone")}:* ${deliveryZone.name}`;
      }
    }

    // Contact info
    textMessage += `\n---\n*${i18n.t("contact_details")}*`;

    for (let key in contactDetails) {
      if (!contactDetails[key]) continue;

      if (
        key !== CheckoutFormFields.RECEIVE_MODE &&
        key !== CheckoutFormFields.DELIVERY_ZONE &&
        key !== CheckoutFormFields.PAYMENT_METHOD
      ) {
        textMessage += `\n*${i18n.t(_snakeCase(key))}:* ${contactDetails[key]}`;
      }
    }

    const messageEnding = rootState[configModule].checkout?.messageEnding;

    if (messageEnding) {
      textMessage += `\n\n${messageEnding}`;
    }

    const wapNumber = rootState[configModule].checkout.phoneNumber.replace(
      /[^0-9]/g,
      ""
    );
    const wapMessage = `https://wa.me/${wapNumber}?text=${encodeURIComponent(
      textMessage
    )}`;

    window.open(wapMessage);

    commit(mutation.DELETE_ITEMS);
  }
};
