<template>
  <div>
    <input
      v-bind="$attrs"
      :value="value"
      @input="$emit('input', $event.target.value)"
      class="mt-1 px-3 py-2 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md"
      :class="{ 'border-red-300': error }"
    />
    <p v-show="error" class="mt-2 text-sm text-red-400">
      {{ error }}
    </p>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,

  name: "BInput",

  props: {
    error: {
      type: String,
      default: ""
    },
    value: {
      type: String,
      default: ""
    }
  }
};
</script>
