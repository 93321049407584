<template>
  <div>
    <textarea
      :value="value"
      @input="$emit('input', $event.target.value)"
      class="px-3 py-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"
      :class="{ 'border-red-300': error }"
    ></textarea>
    <p v-show="error" class="mt-2 text-sm text-red-400">
      {{ error }}
    </p>
  </div>
</template>

<script>
export default {
  name: "BTextarea",

  props: {
    error: {
      type: String,
      default: ""
    },
    value: {
      type: String,
      default: ""
    }
  }
};
</script>
