<template>
  <div id="biew-app">
    <list-view v-if="isWidget" />
    <router-view v-else />
  </div>
</template>

<script>
import ListView from "./views/ListView";
const defaultFavicon =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAXNJREFUeNrsV9GNwjAMDdYN0A/4Z4QyAWUDNiidhHYS6AZs0GOCdgT+4YMRsJFdWT4qXe+SFqE+yYqUWnkvjlPHM/cCi/kiwSFF26JF7n+4o53Qyuvt+m0/zgzxEocDWuLCgARkKOTyQwCSxzhUL3bc8C4sYuV7YbOI2M9GZIMimlYA77w25AXaUas10apUpAr0yzv8aO0d2t6IWNHawBMHRS4f8y7yPqA1WNxKRTJiTgeccPrM2/D4BK+5UVMJcQNnu1OhbAIloIgo1FQKfNUERxcemmML6uwbH2f+m5zgm/XMBTCZORRaLnAjYxIwuoAvT+us8aeS9/Bf+haQ/LWCfswRUJ0/9/BP5Rh8CTh3leOOEr0WAdM1nASAeUAOhUgLkNIY8wMyKJhDXsp34KZBsBtg95rjRAJKNbHn/iDU7mPzPC+B2yXdMlUhRKjGp/17ErckYWbe7DVVNx85QWtwpaxN75G9T2v2Fs3pWO35Q4ABAOmHkX85SQ58AAAAAElFTkSuQmCC";

export default {
  name: "App",

  components: {
    ListView
  },

  props: {
    id: {
      type: String,
      default: ""
    },
    uid: {
      type: String,
      default: ""
    }
  },

  metaInfo() {
    if (!this.isWidget) {
      const metaDescription = this.metaDescription;
      const metaTitle = this.metaTitle;
      const favicon = this.favicon;
      let proMetaInfo = {};

      if (this.isPro) {
        proMetaInfo = {
          title: "",
          titleTemplate: titleChunk => {
            return titleChunk ? `${titleChunk} | ${metaTitle}` : metaTitle;
          },
          meta: [
            { name: "description", content: metaDescription },
            { property: "og:title", content: metaTitle },
            { property: "og:type", content: "website" },
            { property: "og:description", content: metaDescription }
          ]
        };
      }

      return {
        title: metaTitle,
        ...proMetaInfo,
        htmlAttrs: {
          lang: "en"
        },
        link: [
          {
            rel: "icon",
            href: favicon || defaultFavicon
          }
        ]
      };
    } else {
      return {};
    }
  },

  computed: {
    favicon() {
      return this.$store.state.config?.favicon;
    },
    isPro() {
      return this.$store.state.config?.isPro;
    },
    isWidget() {
      return process.env.VUE_APP_MODE === "widget";
    },
    metaDescription() {
      return this.$store.state.config?.metaFields?.homeDescription || "";
    },
    metaTitle() {
      return (
        this.$store.state.config?.metaFields?.homeTitle ||
        this.$store.state.config?.name
      );
    }
  },

  created() {
    this.$i18n.locale = navigator.language.substring(0, 2);
  },

  mounted() {
    if (this.isWidget) {
      this.$store.dispatch("setConfig", { uid: this.uid, websiteId: this.id });
    }
  }
};
</script>

<style lang="scss" scoped>
#biew-app {
  position: relative;
}
</style>
