<template>
  <div v-if="filteredOptions.length">
    <div>
      <label class="font-bold"> {{ name }} {{ required ? "*" : "" }} </label>
      <div class="flex flex-col">
        <b-checkbox
          v-for="(option, index) in filteredOptions"
          :id="`${index}-option`"
          :key="`${index}-option`"
          :name="name"
          :native-value="{
            name: option.name,
            value: item[option.name]
          }"
          :value="newValue"
          @input="valueChange($event)"
        >
          {{ option.name }} - {{ item[option.name] }}
        </b-checkbox>
      </div>
    </div>
  </div>
</template>

<script>
import BCheckbox from "./BCheckbox";

export default {
  name: "BMultipleOptionSelection",

  components: { BCheckbox },

  props: {
    item: {
      type: Object,
      default: () => {}
    },
    name: {
      type: String,
      required: true
    },
    options: {
      type: Array,
      required: true
    },
    value: {
      type: Array,
      required: true
    },
    required: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      newValue: this.value
    };
  },

  computed: {
    filteredOptions() {
      return this.options.filter(opt => {
        return this.item[opt.name];
      });
    }
  },

  methods: {
    valueChange(item) {
      this.newValue = item;
      this.$emit("input", item);
    }
  }
};
</script>
