import * as mutation from "./mutation-types";

export default {
  [mutation.ADD_ITEM](state, item) {
    state.items = [...state.items, item];
  },
  [mutation.DELETE_ITEMS](state) {
    state.items = [];
  },
  [mutation.INCREMENT_ITEM](state, item) {
    state.items = state.items.map(cartItem => {
      if (cartItem.id === item.id && cartItem.index === item.index) {
        cartItem.amount += 1;
      }
      return cartItem;
    });
  },
  [mutation.REMOVE_ITEM](state, item) {
    state.items = state.items.filter(cartItem => {
      return `${cartItem.id}-${cartItem.index}` !== `${item.id}-${item.index}`;
    });
  },
  [mutation.SET_CURRENCY](state, currency) {
    state.currency = currency;
  },
  [mutation.SET_SELECTED](state, selected) {
    state.selected = { ...selected };
  },
  [mutation.SHOW_SHOPPING_CART](state, show) {
    state.showShoppingCart = show;
  },
  [mutation.SHOW_PRODUCT_OPTIONS_MODAL](state, show) {
    state.showProductOptionsModal = show;
  },
  [mutation.SUBSTRACT_ITEM](state, item) {
    state.items = state.items.map(cartItem => {
      if (cartItem.id === item.id) {
        cartItem.amount -= 1;
      }
      return cartItem;
    });
  }
};
