import store from "../store";
import currency from "currency.js";

export default {
  format(amount) {
    const currencyFormat = store.state.checkout?.currency;
    return currency(amount, {
      symbol: currencyFormat?.symbol || "$",
      pattern: currencyFormat?.pattern || "!#"
    }).format();
  }
};
