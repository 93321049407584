<template>
  <footer class="bg-white">
    <div class="container mx-auto px-4 py-6 text-center text-gray-900">
      <p v-if="branded">
        {{ $t("this_website_was_built_using") }}
        <strong>
          <a href="https://getbiew.com/">Biew</a>
        </strong>
      </p>
      <div v-else>
        <p>
          {{ description }}
        </p>
        <router-link v-if="pivacyPolicy" to="privacy-policy" class="mt-3">
          {{ $t("privacy_policy") }}
        </router-link>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "TheFooter",

  props: {
    branded: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    config() {
      return this.$store.state.config.footer;
    },
    description() {
      return this.config.description;
    },
    pivacyPolicy() {
      return this.$store.state.config.privacyPolicy;
    }
  }
};
</script>
