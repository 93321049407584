<template>
  <div v-if="filteredOptions.length">
    <label class="font-bold"> {{ name }} {{ required ? "*" : "" }} </label>
    <div class="flex flex-col">
      <b-radio
        v-for="(option, index) in filteredOptions"
        :key="`${index}-option`"
        :name="name"
        :native-value="{
          name: option.name,
          value: item[option.name]
        }"
        :value="value"
        @input="valueChange($event)"
      >
        {{ option.name }} - {{ item[option.name] }}
      </b-radio>
    </div>
  </div>
</template>

<script>
import BRadio from "./BRadio";

export default {
  name: "BSingleOptionSelection",

  components: {
    BRadio
  },

  props: {
    item: {
      type: Object,
      default: () => {}
    },
    name: {
      type: String,
      required: true
    },
    options: {
      type: Array,
      required: true
    },
    value: { type: Object, default: null },
    required: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    filteredOptions() {
      return this.options.filter(opt => {
        return this.item[opt.name];
      });
    }
  },

  methods: {
    valueChange(item) {
      this.$emit("input", item);
    }
  }
};
</script>
