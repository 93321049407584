import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import { setOptions, bootstrap } from "vue-gtag";
import ApiSites from "@/services/sites.js";
import createPersistedState from "vuex-persistedstate";
import checkout from "./modules/checkout";
import ActionTypes from "@/constants/action-types.js";

Vue.use(Vuex);

const checkoutState = createPersistedState({
  paths: ["checkout"]
});

export default new Vuex.Store({
  state: {
    filters: [],
    items: [],
    searchTerm: "",
    selectedCategory: null,
    config: {},
    loading: true
  },
  actions: {
    async setConfig({ commit }, data) {
      try {
        commit("SET_LOADING", true);
        const _uid = data?.uid;
        const _websiteId = data?.websiteId;
        const isWidget = process.env.VUE_APP_MODE === "widget";
        let websiteId = "";

        if (isWidget) {
          Vue.prototype.$http.defaults.baseURL = process.env.VUE_APP_API_URL;
          Vue.prototype.$http.defaults.headers.common["x-uid"] = _uid;
          websiteId = _websiteId;
        } else {
          const data = await fetch("/config.json");
          const localConfig = await data.json();
          Vue.prototype.$http.defaults.baseURL = localConfig.apiUrl;
          Vue.prototype.$http.defaults.headers.common["x-uid"] =
            localConfig.uid;
          websiteId = localConfig.websiteId;
        }

        const config = (await ApiSites.config({ websiteId })).data;
        commit("SET_CONFIG", config.website);

        if (config.website.isPro && config.website.gaId) {
          setOptions({
            config: {
              id: config.website.gaId,
              params: {
                send_page_view: true
              }
            }
          });
          bootstrap().then(_ => {});
        }
        commit("SET_LOADING", false);
      } catch (error) {
        console.log(error);
      }
    },

    async getItems({ commit, state }) {
      try {
        const params = { filterBy: state.config?.listView?.filterBy?.name };
        const sheetName = state?.config?.sheet?.sheet;

        if (sheetName) {
          params.sheetName = sheetName;
        }

        const res = await axios.get(
          `api/sheets/${state.config.sheet.id}/items`,
          {
            params
          }
        );
        commit("setItems", res.data.items);
        commit("setFilters", res.data.filters);
      } catch (error) {
        console.log(error);
      }
    }
  },
  mutations: {
    setItems(state, value) {
      state.items = value;
    },
    setFilters(state, value) {
      state.filters = value;
    },
    setSearchTerm(state, value) {
      state.searchTerm = value;
    },
    setSelectedCategory(state, value) {
      state.selectedCategory = value;
    },
    ["SET_CONFIG"](state, config) {
      state.config = config;
    },
    ["SET_LOADING"](state, loading) {
      state.loading = loading;
    }
  },
  getters: {
    isCheckoutEnabledInListView(state) {
      return state.config.listView?.cta?.type === ActionTypes.NUM_CHECKOUT;
    },
    isCheckoutEnabledInDetailView(state) {
      return state.config.detailView?.cta?.type === ActionTypes.NUM_CHECKOUT;
    },
    isShoppingCartEnabled(_, getters) {
      return (
        getters.isCheckoutEnabledInListView &&
        getters.isCheckoutEnabledInDetailView
      );
    }
  },
  modules: {
    checkout
  },
  plugins: [checkoutState]
});
