<template>
  <font-awesome-icon
    v-if="source === 'font-awesome'"
    v-bind="$attrs"
    :icon="name"
  />
  <span
    v-else-if="source === 'custom'"
    v-bind="$attrs"
    :class="customIconClass"
  />
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library as fontAwesomeIconLibrary } from "@fortawesome/fontawesome-svg-core";
import _camelCase from "lodash-es/camelCase";

const faWhatsapp = {
  prefix: "fas",
  iconName: "whatsapp",
  icon: [
    24,
    24,
    [],
    null,
    "M19.077,4.928 C16.995,2.845 14.155,1.794 11.173,2.034 C7.164,2.356 3.65,5.144 2.474,8.99 C1.634,11.738 1.987,14.607 3.355,16.977 L2.059,21.28 C1.935,21.693 2.312,22.082 2.729,21.971 L7.233,20.764 C8.692,21.56 10.334,21.979 12.006,21.98 L12.01,21.98 C16.205,21.98 20.081,19.414 21.422,15.439 C22.728,11.563 21.762,7.616 19.077,4.928 Z M16.898,15.554 C16.69,16.137 15.671,16.699 15.213,16.74 C14.755,16.782 14.326,16.947 12.218,16.116 C9.681,15.116 8.079,12.515 7.955,12.349 C7.83,12.182 6.936,10.996 6.936,9.768 C6.936,8.54 7.581,7.936 7.81,7.687 C8.039,7.437 8.309,7.375 8.476,7.375 C8.642,7.375 8.809,7.375 8.954,7.381 C9.132,7.388 9.329,7.397 9.516,7.812 C9.738,8.306 10.223,9.54 10.285,9.665 C10.347,9.79 10.389,9.936 10.306,10.102 C10.223,10.268 10.181,10.372 10.057,10.518 C9.932,10.664 9.795,10.843 9.683,10.955 C9.558,11.079 9.428,11.215 9.573,11.464 C9.719,11.714 10.219,12.531 10.961,13.192 C11.915,14.042 12.718,14.305 12.968,14.431 C13.218,14.556 13.363,14.535 13.509,14.368 C13.655,14.202 14.133,13.64 14.299,13.39 C14.465,13.14 14.632,13.182 14.861,13.265 C15.09,13.348 16.317,13.952 16.566,14.077 C16.816,14.202 16.982,14.264 17.044,14.368 C17.106,14.471 17.106,14.971 16.898,15.554 Z"
  ]
};

fontAwesomeIconLibrary.add(
  require("@fortawesome/free-solid-svg-icons/faArrowLeft").definition,
  require("@fortawesome/free-solid-svg-icons/faChevronLeft").definition,
  require("@fortawesome/free-solid-svg-icons/faChevronRight").definition,
  require("@fortawesome/free-solid-svg-icons/faCreditCard").definition,
  require("@fortawesome/free-solid-svg-icons/faExternalLinkAlt").definition,
  require("@fortawesome/free-solid-svg-icons/faListUl").definition,
  require("@fortawesome/free-solid-svg-icons/faMinus").definition,
  require("@fortawesome/free-solid-svg-icons/faMoneyBillWave").definition,
  require("@fortawesome/free-solid-svg-icons/faPlus").definition,
  require("@fortawesome/free-solid-svg-icons/faShoppingCart").definition,
  require("@fortawesome/free-solid-svg-icons/faStore").definition,
  require("@fortawesome/free-solid-svg-icons/faTimes").definition,
  require("@fortawesome/free-solid-svg-icons/faTrashAlt").definition,
  require("@fortawesome/free-solid-svg-icons/faTruck").definition,
  faWhatsapp
);

export default {
  components: {
    FontAwesomeIcon
  },
  inheritAttrs: false,
  props: {
    source: {
      type: String,
      default: "font-awesome"
    },
    name: {
      type: String,
      required: true
    }
  },
  computed: {
    customIconClass() {
      return this.$style[_camelCase("icon-custom-" + this.name)];
    }
  }
};
</script>
