<template>
  <label class="block text-sm font-medium text-gray-700">
    <slot /> {{ required ? "*" : "" }}
  </label>
</template>

<script>
export default {
  name: "BLabel",

  props: {
    required: {
      type: Boolean,
      default: false
    }
  }
};
</script>
