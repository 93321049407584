<template>
  <div class="h-full">
    <loading-screen v-if="loading && !isWidget" />
    <b-hero
      v-if="showHero"
      :image-url="hero.image.value"
      :title="hero.title.value"
      :subheading="hero.subheading.value"
    >
    </b-hero>
    <div v-if="!loading" class="container mx-auto px-4 sm:px-0">
      <markup-content v-if="topContent" :content="topContent" />
      <div v-if="filterByField" class="filters is-hidden-touch mt-4">
        <b-button
          :variant="selectedCategory === null ? 'primary' : 'secondary'"
          class="mr-4 mb-4"
          @click="setSelectedCategory(null)"
        >
          {{ $t("all") }}
        </b-button>
        <b-button
          v-for="(filter, index) in filters"
          :key="`filter-${index}`"
          :variant="filter === selectedCategory ? 'primary' : 'secondary'"
          class="mr-4 mb-4"
          @click="setSelectedCategory(filter)"
        >
          {{ filter }}
        </b-button>
      </div>
      <div
        v-if="config"
        class="flex flex-wrap -mx-2"
        :class="{ 'mt-6': !filterByField }"
      >
        <div
          v-for="item in filteredItems"
          :key="`item-${item.id}`"
          class="w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/4 px-2 mb-4"
        >
          <router-link
            v-if="item.slug && !hideSinglePage && !isWidget"
            :to="{ name: 'detail-view', params: { slug: item.slug } }"
          >
            <b-content-card :item="item" :config="config" />
          </router-link>
          <b-content-card v-else :item="item" :config="config" />
        </div>
      </div>
      <div>
        <div v-show="!items.length">
          {{ $t("no_data_to_show") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BContentCard from "@/components/BContentCard";
import BHero from "@/components/BHero";
import LoadingScreen from "@/components/LoadingScreen";
import MarkupContent from "@/components/MarkupContent";

export default {
  name: "ListView",

  components: {
    BContentCard,
    BHero,
    LoadingScreen,
    MarkupContent
  },

  data() {
    return {
      loading: true
    };
  },

  computed: {
    isPro() {
      return this.$store.state.config?.isPro;
    },
    isWidget() {
      return process.env.VUE_APP_MODE === "widget";
    },
    config() {
      return this.$store.state.config.listView;
    },
    lang() {
      return this.$store.state.config.lang;
    },
    items() {
      return this.$store.state.items;
    },
    filteredItems() {
      if (this?.config?.filterBy?.name) {
        return this.items.filter(item =>
          this.selectedCategory === null
            ? this.isInString(item[this.config.filterBy.name], this.searchTerm)
            : item[this.config.filterBy.name]?.trim() ===
                this.selectedCategory.trim() &&
              this.isInString(
                item[this.config.filterBy.name]?.trim(),
                this.searchTerm
              )
        );
      }
      return this.items;
    },
    filters() {
      return this.$store.state.filters;
    },
    filterByField() {
      return this.config?.filterBy;
    },
    searchTerm() {
      return this.$store.state.searchTerm;
    },
    selectedCategory() {
      return this.$store.state.selectedCategory;
    },
    hideSinglePage() {
      return this.$store.state.config.detailView.hide;
    },
    showHero() {
      if (this.hero && this.hero.show) {
        return true;
      }
      return false;
    },
    hero() {
      return this.config?.hero;
    },
    topContent() {
      return this.config?.topContent;
    }
  },

  watch: {
    config(newVal) {
      if (newVal) {
        this.getItems();
      }
    }
  },

  beforeMount() {
    if (!this.items.length && this.config) {
      this.getItems();
    } else if (!this.config) {
      this.loading = true;
    } else {
      this.loading = false;
    }
  },

  methods: {
    isInString(a, b) {
      return b.toLowerCase()
        ? a.toLowerCase().search(b.toLowerCase()) > -1
        : true;
    },
    setSelectedCategory(category) {
      this.$store.commit("setSelectedCategory", category);
    },

    async getItems() {
      this.loading = true;
      await this.$store.dispatch("getItems");
      this.loading = false;
    }
  }
};
</script>

<style lang="scss" scoped>
#biew-app {
  .widget-blocked {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: rgba(255, 255, 255, 0.9);
    overflow: none;
  }

  .filters {
    display: flex;
    flex-wrap: wrap;
  }
}
</style>
