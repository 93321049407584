<template>
  <div class="h-96 w-full relative">
    <div
      v-lazy:background-image="parsedImageUrl"
      class="h-full bg-no-repeat bg-center bg-cover bg-gray-400"
    ></div>
    <div
      class="absolute top-0 left-0 w-full h-full flex justify-center items-center text-white p-4 py-6 lg:py-8"
    >
      <div class="text-center">
        <h1
          class="text-4xl sm:text-6xl lg:text-7xl leading-none font-extrabold tracking-tight mb-6"
        >
          {{ title }}
        </h1>
        <p
          class="max-w-screen-lg text-lg sm:text-2xl sm:leading-10 font-medium"
        >
          {{ subheading }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import StringUtils from "@/utils/string";

export default {
  name: "BHero",

  props: {
    imageUrl: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      default: ""
    },
    subheading: {
      type: String,
      default: ""
    }
  },

  computed: {
    parsedImageUrl() {
      if (this.imageUrl) {
        return StringUtils.directDownloadLink(this.imageUrl) || this.imageUrl;
      }

      return this.imageUrl;
    }
  }
};
</script>
