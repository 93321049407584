<template>
  <div class="checkout-form width-full">
    <form @submit.prevent="sendOrder">
      <div class="bg-white px-4 pt-5 pb-4 sm:p-6">
        <div class="grid grid-cols-6 gap-6 mb-6">
          <div v-if="showField('branch')" class="col-span-6">
            <b-label for="branch" :required="formFields.branch.required">
              {{ $t("branch") }}
            </b-label>
            <select
              v-model="form.branch"
              id="branch"
              name="branch"
              autocomplete="branch"
              class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              :class="{
                'border-red-300':
                  form.branch.trim().length === 0 ? errors.branch : ''
              }"
            >
              <option disabled value="">{{ $t("select_branch") }}</option>
              <option
                v-for="(branch, index) in branches"
                :key="`branch-${index}`"
              >
                {{ branch.name }}
              </option>
            </select>
            <p
              v-show="form.branch.trim().length === 0 ? errors.branch : ''"
              class="mt-2 text-sm text-red-400"
            >
              {{
                $t("field_is_required", {
                  field: this.$t("branch")
                })
              }}
            </p>
          </div>

          <div v-if="showField('name')" class="col-span-6">
            <b-label for="name" :required="formFields.name.required">
              {{ $t("name") }}
            </b-label>
            <b-input
              type="text"
              name="name"
              id="name"
              autocomplete="family-name"
              v-model.trim="form.name"
              :error="form.name.trim().length === 0 ? errors.name : ''"
            />
          </div>

          <div v-if="showField('email')" class="col-span-6">
            <b-label for="email_address" :required="formFields.email.required">
              {{ $t("email") }}
            </b-label>
            <b-input
              type="text"
              name="email_address"
              id="email_address"
              autocomplete="email"
              v-model.trim="form.email"
              :error="form.email.trim().length === 0 ? errors.email : ''"
            />
          </div>

          <div v-if="showField('phoneNumber')" class="col-span-6 sm:col-span-3">
            <b-label for="phone" :required="formFields.phoneNumber.required">
              {{ $t("phone_number") }}
            </b-label>
            <b-input
              type="text"
              name="phone"
              id="phone"
              autocomplete="phone"
              v-model.trim="form.phoneNumber"
              :error="
                form.phoneNumber.trim().length === 0 ? errors.phoneNumber : ''
              "
            />
          </div>

          <div v-if="showField('country')" class="col-span-6 sm:col-span-3">
            <b-label for="country" :required="formFields.country.required">
              {{ $t("country") }}
            </b-label>
            <b-input
              id="country"
              name="country"
              autocomplete="country"
              v-model.trim="form.country"
              :error="form.country.trim().length === 0 ? errors.country : ''"
            />
          </div>

          <div v-if="showField('address')" class="col-span-6">
            <b-label
              for="street_address"
              :required="formFields.address.required"
            >
              {{ $t("address") }}
            </b-label>
            <b-input
              type="text"
              name="street_address"
              id="street_address"
              autocomplete="street-address"
              v-model.trim="form.address"
              :error="form.address.trim().length === 0 ? errors.address : ''"
            />
          </div>

          <div
            v-if="showField('city')"
            class="col-span-6 sm:col-span-6 lg:col-span-2"
          >
            <b-label for="city" :required="formFields.city.required">
              {{ $t("city") }}
            </b-label>
            <b-input
              type="text"
              name="city"
              id="city"
              v-model.trim="form.city"
              :error="form.city.trim().length === 0 ? errors.city : ''"
            />
          </div>

          <div
            v-if="showField('state')"
            class="col-span-6 sm:col-span-3 lg:col-span-2"
          >
            <b-label for="state" :required="formFields.state.required">
              {{ $t("state") }}
            </b-label>
            <b-input
              type="text"
              name="state"
              id="state"
              v-model.trim="form.state"
              :error="form.state.trim().length === 0 ? errors.state : ''"
            />
          </div>

          <div
            v-if="showField('zipCode')"
            class="col-span-6 sm:col-span-3 lg:col-span-2"
          >
            <b-label for="postal_code" :required="formFields.zipCode.required">
              {{ $t("zip_code") }}
            </b-label>
            <b-input
              type="text"
              name="postal_code"
              id="postal_code"
              autocomplete="postal-code"
              v-model.trim="form.zipCode"
              :error="form.zipCode.trim().length === 0 ? errors.zipCode : ''"
            />
          </div>

          <div v-if="showField('notes')" class="col-span-6">
            <b-label for="notes" :required="formFields.notes.required">
              {{ $t("notes") }}
            </b-label>
            <div class="mt-1">
              <b-textarea
                v-model.trim="form.notes"
                :error="form.notes.trim().length === 0 ? errors.notes : ''"
                id="notes"
                name="notes"
                rows="3"
              />
            </div>
          </div>
        </div>

        <div v-if="showPaymentMethods" class="col-span-6 mb-3">
          <b-label class="mb-2">
            {{ $t("how_will_you_pay") }}
          </b-label>
          <b-button
            @click="form.paymentMethod = 1"
            :variant="form.paymentMethod === 1 ? 'primary' : 'secondary'"
            :disabled="!isCashPaymentEnabled"
            class="w-full sm:w-auto | mb-3 sm:mb-0 sm:mr-3"
          >
            <b-icon class="mr-3" name="money-bill-wave" />
            <div>{{ $t("cash") }}</div>
          </b-button>
          <b-button
            @click="form.paymentMethod = 2"
            :variant="form.paymentMethod === 2 ? 'primary' : 'secondary'"
            :disabled="!isCreditCardPaymentEnabled"
            class="w-full sm:w-auto"
            :class="{ 'mb-3 sm:mb-0 sm:mr-3': customPaymentMethods.length }"
          >
            <b-icon class="mr-3" name="credit-card" />
            <div>{{ $t("credit_card") }}</div>
          </b-button>
          <b-button
            v-for="(customPaymentMethod, index) in customPaymentMethods"
            :key="`custom-payment-method-${index}`"
            @click="form.paymentMethod = customPaymentMethod"
            :variant="
              form.paymentMethod === customPaymentMethod
                ? 'primary'
                : 'secondary'
            "
            class="w-full sm:w-auto sm:mr-3 mb-3"
          >
            <div>{{ customPaymentMethod }}</div>
          </b-button>
        </div>

        <div v-if="showReceiveModes" class="col-span-6">
          <b-label class="mb-2">
            {{ $t("how_would_you_like_to_receive_your_order") }}
          </b-label>
          <b-button
            @click="selectReceiveMode(1)"
            :variant="form.receiveMode === 1 ? 'primary' : 'secondary'"
            :disabled="!isPickUpEnabled"
            class="w-full sm:w-auto | sm:mb-0 sm:mr-3"
          >
            <b-icon class="mr-3" name="store" />
            <div>{{ $t("pick_up_at_store") }}</div>
          </b-button>
          <b-button
            v-if="!hasDeliveryZones && isDeliveryEnabled"
            @click="selectReceiveMode(2)"
            :variant="form.receiveMode === 2 ? 'primary' : 'secondary'"
            :disabled="!isDeliveryEnabled"
            class="w-full sm:w-auto | mt-3 sm:mt-0"
          >
            <b-icon class="mr-3" name="truck" />
            <div class="flex flex-col sm:flex-row">
              <div>{{ $t("deliver") }}</div>
              <div v-if="isDeliveryEnabled && deliveryFee" class="ml-1">
                + {{ deliveryFee }}
              </div>
            </div>
          </b-button>
          <b-button
            v-for="(zone, index) in deliveryZones"
            :key="`delivery-zone-${index}`"
            @click="selectReceiveMode(2, zone)"
            :variant="
              form.receiveMode === 2 &&
              form.deliveryZone &&
              form.deliveryZone.name === zone.name
                ? 'primary'
                : 'secondary'
            "
            class="w-full sm:w-auto mt-3"
            :class="{
              'sm:mt-3': index > 0,
              'sm:mt-0': index === 0
            }"
          >
            <b-icon class="mr-3" name="truck" />
            <div class="flex flex-col sm:flex-row">
              <div>
                {{
                  $t("deliver_to", {
                    zone: zone.name
                  })
                }}
              </div>
              <div v-if="deliveryFee" class="ml-1">
                + {{ formatMoney(zone.cost) }}
              </div>
            </div>
          </b-button>
        </div>
      </div>
      <div
        class="px-4 py-4 sm:py-3 bg-gray-200 text-right sm:px-6 sm:flex sm:flex-row-reverse"
      >
        <b-button
          type="submit"
          class="w-full sm:w-auto border-green-600 bg-green-500 hover:bg-green-600 | mb-3 sm:mb-0 sm:mt-0 text-white"
        >
          {{ $t("send_whatsapp") }}
          <b-icon name="whatsapp" class="ml-2" size="lg" />
        </b-button>
        <b-button
          @click="$emit('cancel')"
          type="button"
          variant="primary"
          class="w-full sm:w-auto | sm:mr-3"
        >
          {{ $t("continue_shopping") }}
        </b-button>
      </div>
    </form>
  </div>
</template>

<script>
import BButton from "../BButton";
import BIcon from "../BIcon";
import BInput from "../BInput";
import BTextarea from "../BTextarea";
import BLabel from "../BLabel";
import PaymentMethods from "@/constants/checkout/payment-method";
import ReceiveModes from "@/constants/checkout/receive-mode";
import money from "@/utils/money";

export default {
  name: "CheckoutForm",

  components: {
    BButton,
    BIcon,
    BInput,
    BTextarea,
    BLabel
  },

  data() {
    return {
      errors: {},
      form: {
        branch: "",
        name: "",
        email: "",
        phoneNumber: "",
        country: "",
        address: "",
        city: "",
        state: "",
        zipCode: "",
        notes: "",
        paymentMethod: 0,
        receiveMode: 0,
        deliveryZone: ""
      }
    };
  },

  computed: {
    branches() {
      return this.checkout?.branches;
    },
    checkout() {
      return this.$store.state.config?.checkout;
    },
    deliveryFee() {
      const cost = this.formFields?.delivery?.cost;
      if (cost) {
        return money.format(cost);
      }
      return false;
    },
    hasDeliveryZones() {
      return this.deliveryZones.length > 0;
    },
    deliveryZones() {
      return this.formFields?.delivery?.zones || [];
    },
    formFields() {
      return this.checkout?.form || {};
    },
    isPickUpEnabled() {
      return this.formFields?.pickup?.available || false;
    },
    isDeliveryEnabled() {
      return this.formFields?.delivery?.available || false;
    },
    showReceiveModes() {
      if (!this.isDeliveryEnabled && !this.isPickUpEnabled) {
        return false;
      }
      return true;
    },
    paymentMethods() {
      return this.formFields?.paymentMethods;
    },
    customPaymentMethods() {
      return this.paymentMethods?.custom || [];
    },
    isCashPaymentEnabled() {
      return this.paymentMethods.cash;
    },
    isCreditCardPaymentEnabled() {
      return this.paymentMethods.creditCard;
    },
    showPaymentMethods() {
      if (
        !this.isCashPaymentEnabled &&
        !this.isCreditCardPaymentEnabled &&
        !this.customPaymentMethods.length
      ) {
        return false;
      }
      return true;
    }
  },

  watch: {
    isPickUpEnabled: {
      immediate: true,
      deep: true,
      handler() {
        this.updateReceiveMode();
        this.updatePaymentMethod();
      }
    }
  },

  methods: {
    sendOrder() {
      this.errors = {};
      let hasErrors = false;
      for (let key in this.formFields) {
        if (!this.form[key] && this.formFields[key].required) {
          hasErrors = true;
          this.errors[key] = this.$t("field_is_required", {
            field: this.$t(key)
          });
        }
      }

      if (!hasErrors) {
        this.$store.dispatch("checkout/sendOrder", this.form).then(() => {
          this.$emit("order-sent");
        });
      }
    },
    showField(field) {
      return this.formFields[field]?.show || false;
    },
    updateReceiveMode() {
      if (this.isPickUpEnabled) {
        this.form.receiveMode = ReceiveModes.NUM_PICKUP;
      } else if (this.isDeliveryEnabled) {
        this.form.receiveMode = ReceiveModes.NUM_DELIVERY;
      } else {
        this.form.receiveMode = 0;
      }
    },
    updatePaymentMethod() {
      if (this.isCashPaymentEnabled) {
        this.form.paymentMethod = PaymentMethods.NUM_CASH;
      } else if (this.isCreditCardPaymentEnabled) {
        this.form.paymentMethod = PaymentMethods.NUM_CREDIT_CARD;
      } else {
        this.form.paymentMethod = 0;
      }
    },
    selectReceiveMode(mode, zone) {
      this.form.receiveMode = mode;

      if (zone && mode === ReceiveModes.NUM_DELIVERY && this.hasDeliveryZones) {
        this.form.deliveryZone = zone;
      }
    },
    formatMoney(amount) {
      return money.format(amount);
    }
  }
};
</script>

<style lang="scss" scoped>
select {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-position: right 0.5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  color-adjust: exact;
  appearance: none;
}
</style>
